import React from "react";
import LandingPage from "../Pages/LandingPage";
import Footer from "./Footer";
import Header from "./Header";

const StartFile = () => {
  return (
    <div>
      <Header />
      <LandingPage />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default StartFile;
