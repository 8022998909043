import React from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [show, setShow] = React.useState(false);

  const changeHeaderColor = () => {
    if (window.scrollY >= 70) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  window.addEventListener("scroll", changeHeaderColor);
  return (
    <>
      {show ? (
        <div>
          <Container bc="white" cl="black">
            <LogoWrap>
              <Logo>victor</Logo>
            </LogoWrap>
            <NavWrapper>
              <Nav>For Technology Experts</Nav>
              <Nav>Business</Nav>
              <Nav>Enterprise</Nav>
              <Nav>About Us</Nav>
            </NavWrapper>
            <Buttons>
              <Button bc="" cl="black" bd="1px solid black">
                Apply for Jobs
              </Button>
              <Button bc="green" cl="black" bd="none">
                Hire Talents
              </Button>
            </Buttons>
            <Menu hcl="black">
              <GiHamburgerMenu />
            </Menu>
          </Container>
        </div>
      ) : (
        <div>
          <Container bc="" cl="white">
            <LogoWrap>
              <Logo>victor</Logo>
            </LogoWrap>
            <NavWrapper>
              <Nav>For Technology Experts</Nav>
              <Nav>Business</Nav>
              <Nav>Enterprise</Nav>
              <Nav>About Us</Nav>
            </NavWrapper>
            <Buttons>
              <Button bc="transparent" cl="white" bd="1px solid white">
                Apply for Jobs
              </Button>
              <Button bc="green" cl="black" bd="none">
                Hire Talents
              </Button>
            </Buttons>
            <Menu hcl="">
              <GiHamburgerMenu />
            </Menu>
          </Container>
        </div>
      )}
    </>
  );
};

export default Header;

const Container = styled.div<{ cl: string; bc: string }>`
  width: 100vw;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  z-index: 99;
  color: ${(props) => props.cl};
  background-color: ${(props) => props.bc};
`;
const LogoWrap = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;
`;
const Buttons = styled.div`
  display: flex;
  margin-right: 40px;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const Button = styled.button<{ bc: string; cl: string; bd: string }>`
  font-size: large;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: ${(props) => props.bd};
  border-radius: 30px;
  background-color: ${(props) => props.bc};
  color: ${(props) => props.cl};
  margin-left: 25px;
  margin-right: 25px;
  transition: all 350ms;
  :hover {
    cursor: pointer;
    transform: scale(1.04);
  }
`;
const Logo = styled.div`
  font-size: xx-large;
  font-weight: bold;
  font-family: cursive;
`;
const NavWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const Nav = styled.div`
  font-size: large;
  margin-left: 35px;
  margin-right: 35px;
`;
const Menu = styled.div<{ hcl: string }>`
  color: ${(props) => props.hcl};
  font-size: 30px;
  margin-right: 50px;
  display: none;
  /* right: 100%; */
  @media screen and (max-width: 960px) {
    display: block;
  }
`;
