import React from "react";
import styled from "styled-components";
import backGround from "../Assets/andela.jpeg";

const LandingPage = () => {
  return (
    <div>
      <Container>
        <Content>
          <HeadText>
            Discover brilliant talent
            <br /> around the world
          </HeadText>
          <AboutText>
            Join Andela to build your team with developers, engineers, product
            designers, and the best remote technology experts.
          </AboutText>
          <Buttons>
            <Button>Hire Talents</Button>
            <Button2>Apply for Jobs</Button2>
          </Buttons>
        </Content>
      </Container>
    </div>
  );
};

export default LandingPage;

const Container = styled.div`
  width: 100vw;
  min-height: 600px;
  display: flex;
  align-items: center;
  background-image: url(${backGround});
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  ::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #173b3f;
    opacity: 0.6;
    position: absolute;
    top: 0;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 40px;
  margin-top: 40px;
  color: white;
`;
const HeadText = styled.h1`
  z-index: 1;
  font-size: 55px;
`;
const AboutText = styled.p`
  z-index: 1;
  font-size: large;
`;
const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Button = styled.button`
  font-size: large;
  width: 200px;
  height: 60px;
  border: none;
  border-radius: 30px;
  background-color: green;
  color: #192c21;
  margin-top: 20px;
  margin-right: 25px;
  z-index: 1;
  transition: all 350ms;
  :hover {
    cursor: pointer;
    transform: scale(1.04);
  }
`;
const Button2 = styled.button`
  font-size: large;
  width: 200px;
  height: 60px;
  border: none;
  border-radius: 30px;
  background-color: whitesmoke;
  color: #192c21;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 25px;
  z-index: 1;
  transition: all 350ms;
  :hover {
    cursor: pointer;
    transform: scale(1.04);
  }
`;
